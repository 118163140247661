/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CardStatus } from './CardStatus';

export type VirtualCardResponse = {
    card_id?: string;
    account_id?: string;
    physical_card_id?: string;
    card_number?: string;
    masked_card_number?: string;
    cvv?: string;
    name_on_card?: string;
    physical_card_type?: VirtualCardResponse.physical_card_type;
    available_balance?: number;
    card_status?: CardStatus;
};

export namespace VirtualCardResponse {

    export enum physical_card_type {
        PRIMARY = 'PRIMARY',
        SECONDARY = 'SECONDARY',
    }


}

