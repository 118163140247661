/**
 * Netlify sets an ID for every site being deployed. We can use that value as a proxy
 * for if we're running in a deployed environment (i.e. not local).
 */
export const isDeployed = process.env.SITE_ID != undefined

/**
 * Both Netlify and GHA set `CI` to true when they execute our code.
 * We can use this value as a proxy for if we're building and not running the app.
 */
export const isBuilding = process.env.CI === 'true'

export type Env =
  | 'test'
  | 'development'
  | 'staging'
  | 'deploy-preview'
  | 'production'
