/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Address } from './Address';
export type SecondaryUserRequest = {
    first_name: string;
    last_name: string;
    email: string;
    date_of_birth?: string;
    phone_number?: string;
    address?: Address;
    citizenship_status?: SecondaryUserRequest.citizenship_status;
    primary_user_id?: string;
    ssn?: string;
};
export namespace SecondaryUserRequest {
    export enum citizenship_status {
        US_CITIZEN = 'US_CITIZEN',
        US_PERMANENT_RESIDENT = 'US_PERMANENT_RESIDENT',
        NON_US_CITIZEN = 'NON_US_CITIZEN',
    }
}

