/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CardStatus } from './CardStatus';

export type CardResponse = {
    user_id: string;
    brand_id: string;
    card_id: string;
    account_id: string;
    masked_card_number: string;
    name_on_card: string;
    card_type: CardResponse.card_type;
    cvv: string;
    expiration: string;
    card_status: CardStatus;
    created_at: string;
    invalid_card_activation_attempts?: number;
    cancellation_date?: string;
};

export namespace CardResponse {

    export enum card_type {
        PRIMARY = 'PRIMARY',
        SECONDARY = 'SECONDARY',
    }


}

