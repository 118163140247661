import type {
  IncomingMessage as IncomingMessageBase,
  ServerResponse,
} from 'http'
import type { SetOption } from 'cookies'
import CookiesBase from 'cookies'
import Keygrip from 'keygrip'
import { isDeployed } from './env'
import { addSeconds } from 'date-fns'
import logger from '~/utils/logger'
import type { Auth0TokenResponse } from '~/axios/auth0-client'
import { verify } from '~/utils/jwt'
import type { BrandProgram } from '~/services/dto/ts/brand'
import { UserService } from '~/services/user'
import type { UserAuthorizationDetails } from '~/services/authorization'

export interface Session extends UserAuthorizationDetails {
  user_id: string
  brand_id: string
  token: string
  expires: Date
}

export const SESSION_COOKIE_NAME = 'session'

/**
 * Retrieves the current active session, if it exists, from the browser's cookies
 *
 * @return {*}  {(string | undefined)} The session retrieved from the browser's cookies
 */
export const getSessionCookie = (): string | undefined =>
  document.cookie
    .split('; ')
    .find(row => row.startsWith(`${SESSION_COOKIE_NAME}=`))
    ?.split('=')[1]

interface IncomingMessage extends IncomingMessageBase {
  protocol?: string
}

export const getClientSession = (): Session | undefined => {
  const sessionCookie = getSessionCookie()
  if (sessionCookie === undefined) return
  return JSON.parse(Buffer.from(sessionCookie, 'base64').toString('utf8'))
}

export class Cookies extends CookiesBase {
  private static KEYS: string[] = [process.env.HTTP_COOKIE_SIGNING_KEY || '']

  constructor(req: IncomingMessage, res: ServerResponse) {
    // This is an annoying hack that's needed for `cookies` to understand that we're
    // serving requests over a secure connection. Its error handling logic relies on
    // req.protocol to determine this, which is an Express concept that's not available
    // in NextJS. To get around this, we're setting this protocol value ourselves.
    // https://github.com/pillarjs/cookies/blob/e44ddaf15c13f13679b2261e08da614099875fc0/index.js#L107
    req.protocol = isDeployed ? 'https' : 'http'

    const keygrip = new Keygrip(Cookies.KEYS)

    super(req, res, { keys: keygrip })
  }

  /**
   * Returns the current session as defined in the session cookie, if one exists.
   *
   * @return {*}  {(Session | undefined)} The current session
   * @memberof Cookies
   */
  getSession(): Session | undefined {
    const value = super.get(SESSION_COOKIE_NAME, { signed: true })

    if (value === undefined) return

    return JSON.parse(Buffer.from(value, 'base64').toString('utf8'))
  }

  /**
   * Sets a new session for the given brand's user, overwriting whatever may already exist.
   *
   * The following default cookie options are set.
   * - `secure` is true when served over SSL (easier for local development)
   * - `httpOnly` is false to ensure the client can access the token needed to make requests to Power's APIs
   * - `expires` is set to that of the token so clients remove the session once it is no longer considered valid on the server
   * https://github.com/pillarjs/cookies#features
   *
   * @param {Auth0TokenResponse} data The Auth0 token response, including both access token and expiration
   * @memberof Cookies
   */
  async setSession(data: Auth0TokenResponse, brandProgram: BrandProgram) {
    // Since we should only set signed cookies on the server, we must ensure the keys
    // exist before doing so. Otherwise we might accidentally set a sensitive unsigned cookie.
    if (Cookies.KEYS.some(key => key === '')) {
      const err = new Error(
        `Missing some/all cookie signing keys. Double check your environment variables to ensure they're defined correctly`
      )
      logger.fatal(err)
      throw err
    }

    const payload = await verify(
      process.env.AUTH0_API_ISSUER_BASE_URL || '',
      process.env.AUTH0_API_AUDIENCE || '',
      data.access_token
    )
    const brand_id = payload[`${process.env.AUTH0_API_AUDIENCE}/brand_id`]
    const user_id = payload[`${process.env.AUTH0_API_AUDIENCE}/user_id`]
    const role = payload[`${process.env.AUTH0_API_AUDIENCE}/role`]

    if (typeof brand_id !== 'string')
      throw new Error(
        `Unable to set session - brand id is invalid: ${brand_id}`
      )
    if (typeof user_id !== 'string')
      throw new Error(`Unable to set session - user id is invalid: ${user_id}`)
    if (role !== null && !UserService.isRoleValid(role))
      throw new Error(`Unable to set session - role is invalid: ${role}`)

    const now = new Date()
    const expires = addSeconds(now, data.expires_in)

    const session: Session = {
      brand_id,
      user_id,
      role: role,
      token: data.access_token,
      expires,
      brand_program_type: brandProgram.program_type,
      brand_program_features: {
        account_roles:
          brandProgram.enabled_feature_flags?.includes('ACCOUNT_ROLES') ??
          false,
        rewards:
          brandProgram.enabled_feature_flags?.includes('REWARDS') ?? false,
      },
    }

    const options: SetOption = {
      sameSite: 'lax',
      signed: true,
      secure: isDeployed,
      httpOnly: false,
      expires,
    }

    this.set(
      SESSION_COOKIE_NAME,
      Buffer.from(JSON.stringify(session), 'utf8').toString('base64'),
      options
    )
  }

  /**
   * Clears the current session.
   *
   * @memberof Cookies
   */
  clearSession(): void {
    this.set(SESSION_COOKIE_NAME)
  }
}
