import { frontendApiClient } from '../axios/frontend-api-client'
import type { SecondaryUserRequest } from '~/services/dto/ts/user'
import { UserInformationResponse } from '~/services/dto/ts/user'
import type { BrandProgramFeatures } from '~/services/authorization'

export interface UserServiceInterface {
  getUserDetails(): Promise<UserInformationResponse | undefined>
  getSecondaryUsers(): Promise<Array<UserInformationResponse>>
  createSecondaryUser(
    request: SecondaryUserRequest
  ): Promise<UserInformationResponse>
  isAdmin(
    brandProgramFeatures: BrandProgramFeatures,
    role: UserInformationResponse.role | null
  ): boolean
  isRoleValid(role: unknown): role is UserInformationResponse.role
}

export const UserService: UserServiceInterface = {
  async getUserDetails() {
    const { data } = await frontendApiClient.get<
      UserInformationResponse | undefined
    >(`user/users`)

    return data
  },

  async getSecondaryUsers() {
    const { data } = await frontendApiClient.get<
      Array<UserInformationResponse>
    >(`/user/users/secondary`)
    return data
  },

  async createSecondaryUser(request) {
    const { data } = await frontendApiClient.post<UserInformationResponse>(
      `user/users/secondary`,
      request
    )
    return data
  },

  isAdmin(
    brandProgramFeatures: BrandProgramFeatures,
    role: UserInformationResponse.role | null
  ): boolean {
    return (
      !brandProgramFeatures.account_roles ||
      role === UserInformationResponse.role.ADMIN
    )
  },

  isRoleValid(role: unknown): role is UserInformationResponse.role {
    return (
      typeof role === 'string' &&
      Object.keys(UserInformationResponse.role).includes(role)
    )
  },
}
