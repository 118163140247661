import '~/styles/globals.css'
import React from 'react'
import { StyledEngineProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { StoreProvider } from '~/components/providers/stores'
import { TemplateProvider } from '~/components/providers/template'

import type { AppProps as NextAppProps, AppContext } from 'next/app'
import { CardModel } from '../services/card'
import { Cookies, getClientSession } from '~/utils/cookies'
import type { CardStoreState } from '~/components/providers/stores/card'
import { AppCacheProvider } from '@mui/material-nextjs/v14-pagesRouter'
import type { ClientSession } from '~/components/providers/session'
import { SessionProvider } from '~/components/providers/session'

interface PageProps {
  card?: CardModel
}

interface AppProps extends NextAppProps<PageProps> {
  pageProps: PageProps
  session: ClientSession
}

export default function App(props: AppProps) {
  const { Component, pageProps, session } = props

  const initialStoreState: CardStoreState | undefined = pageProps.card
    ? {
        card: new CardModel(pageProps.card),
      }
    : undefined

  const brandId = session?.brand_id ?? ''

  return (
    <StyledEngineProvider injectFirst>
      <AppCacheProvider {...props}>
        <SessionProvider session={session}>
          <TemplateProvider brandId={brandId}>
            <StoreProvider initialState={initialStoreState}>
              <CssBaseline />
              <Component {...pageProps} />
            </StoreProvider>
          </TemplateProvider>
        </SessionProvider>
      </AppCacheProvider>
    </StyledEngineProvider>
  )
}

App.getInitialProps = async (appContext: AppContext) => {
  const { Component } = appContext

  // calls page's `getInitialProps` and fills `appProps.pageProps`
  let pageProps: PageProps = {}
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(appContext.ctx)
  }

  if (appContext.ctx.req === undefined || appContext.ctx.res === undefined) {
    return { pageProps, session: getClientSession() }
  }

  const cookies = new Cookies(appContext.ctx.req, appContext.ctx.res)

  // Throw the token away before passing the session to the client
  const { token: _, ...session } = cookies.getSession() ?? {}

  return { pageProps, session }
}
