/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SessionDetails = {
    brand_id?: string;
    user_id?: string;
    role?: SessionDetails.role;
};
export namespace SessionDetails {
    export enum role {
        SUPER_ADMIN = 'SUPER_ADMIN',
        ADMIN = 'ADMIN',
        EMPLOYEE = 'EMPLOYEE',
    }
}

