import {
  createDefaultRewards,
  createTypographyStyle,
} from '~/constants/templates/utils'
import type { Template } from '../template'
import { PROGRAM_STATUS } from '../template'

const fontFace = `
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local('Open Sans'), url(/fonts/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local('Open Sans'), url(/fonts/OpenSans-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local('Open Sans'), url(/fonts/OpenSans-Bold.ttf) format('truetype');
}
`

const BRAND_NAME = 'LawPay'

export const Affinipay: Template = {
  usePrequal: false,
  gradient: '#003a70',
  cardOrientation: 'horizontal',
  programStatus: PROGRAM_STATUS.Active,
  hiddenPaths: [
    '/transactions',
    '/lock-unlock',
    '/secondary-card-users',
    '/replace',
  ],
  copy: {
    subdomain: null,
    brandName: BRAND_NAME,
    productName: 'Smart Spend',
    bankPartner: 'Emprise Bank',
    email: 'lawpaycard@support.lawpay.com',
    links: {
      faq: 'us',
      homepage: 'https://smartspend.mycase.com',
      legal: {
        loyaltyProgram: '',
        autopayAgreement: '',
        cardholderAgreement: '',
        ecommDisclosure: '',
        autodialerAuth: '',
        creditReportAuth: '',
      },
    },
    images: {
      logo: {
        src: '/brand/images/affinipay/logo.svg',
        width: 150,
        height: 50,
      },
      cardFrontSrc: '/brand/images/affinipay/card.svg',
    },

    application: {
      title: [
        `Streamline your firm's spending.`,
        'Full visibility, full control.',
      ],
      incentives: [
        {
          title: 'No annual Fee',
        },
        {
          title: 'Free Smart Spend software',
        },
        {
          title: 'Automated expense tracking',
        },
        {
          title: 'Link receipts to cases',
        },
        {
          title: 'Real-time spending insights',
        },
      ],
      aprLow: '18.49%',
      aprHigh: '32.49%',
      feesRows: [
        {
          title: 'Annual Fee',
          explanation: '$100',
        },
        {
          title: 'Foreign Transaction Fee',
          explanation: '3% of the amount of each transaction',
        },
        {
          title: 'Late Payment Fee',
          explanation: '$40',
        },
        {
          title: 'Returned Payment Fee',
          explanation: '$40',
        },
      ],
    },
    rewards: createDefaultRewards(),
    phoneNumber: '+1.999.999.9999',
    offers: [],
    benefits: [],
  },
  theme: {
    palette: {
      primary: {
        main: '#212529',
      },
      secondary: {
        main: '#FFFFFF',
      },
      neutral: {
        main: '',
        light: '#6E728D',
        dark: '#09202c',
      },
      accent: {
        main: '#003566',
      },
      error: {
        main: '#dc3545',
      },
      warning: {
        main: '#ffc107',
      },
      info: {
        main: '#0a69ae',
      },
      success: {
        main: '#28a745',
      },
      brand: {
        main: '#0a69ae',
      },
      background: {
        default: '#FFFFFF',
        light: '#f8f9fa',
      },
    },
    typography: {
      fontFamily: 'Open Sans, sans-serif',
      h1: createTypographyStyle(700, 34, '38px'),
      h2: createTypographyStyle(600, 24, '30px'),
      h3: createTypographyStyle(700, 22, '28px'),
      subtitle1: createTypographyStyle(700, 16, '24px'),
      subtitle2: createTypographyStyle(700, 14, '26px'),
      body1: createTypographyStyle(400, 16, '24px'),
      body2: createTypographyStyle(400, 14, '26px'),
      overline: createTypographyStyle(700, 12, '16px'),
      caption: createTypographyStyle(400, 12, '20px'),
      button: {
        textTransform: 'none',
      },
      link: {
        fontSize: 16,
        lineHeight: '24px',
        letterSpacing: 0,
      },
    },
    shape: {
      borderRadius: 1.5,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: fontFace,
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 6,
          },
          containedPrimary: {
            backgroundColor: '#0a69ae',
            ':hover': {
              backgroundColor: '#074674',
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: '#FFFFFF',
            button: {
              color: '#003a70',
            },
          },
        },
      },
    },
    widget: {
      boxShadow: '0 0.1rem 0.25rem rgba(0, 0, 0, 0.05)',
    },
    nav: {
      listItemOverride: {
        mx: 4,
        my: 1,
        py: 1,
        borderRadius: 6,
        fontWeight: 500,
        '&:hover': {
          fontWeight: 700,
        },
      },
    },
  },
}
