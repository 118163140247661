/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Address } from './Address';
export type UserInformationResponse = {
    user_id: string;
    role?: UserInformationResponse.role;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    email?: string;
    date_of_birth?: string;
    phone_number?: string;
    ssn?: string;
    address?: Address;
    citizenship_status?: UserInformationResponse.citizenship_status;
    business_name?: string;
    business_id?: string;
    primary_user_id?: string;
    external_product_id?: string;
};
export namespace UserInformationResponse {
    export enum role {
        SUPER_ADMIN = 'SUPER_ADMIN',
        ADMIN = 'ADMIN',
        EMPLOYEE = 'EMPLOYEE',
    }
    export enum citizenship_status {
        US_CITIZEN = 'US_CITIZEN',
        US_PERMANENT_RESIDENT = 'US_PERMANENT_RESIDENT',
        NON_US_CITIZEN = 'NON_US_CITIZEN',
    }
}

