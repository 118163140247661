/**
 * This file disables `no-console` rule and drops console statements inside methods.
 * We want to send messages/errors to standard output so that loggings services like Datadog are
 * able to pick up our logs.
 */
/* eslint-disable no-console */

import { captureMessage, captureException } from '@sentry/nextjs'
import type { CaptureContext } from '@sentry/types'

type PowerLoggerContext = Record<string, unknown> & { user_id?: string }

class Logger {
  /**
   * @param {string} message console debug message to display
   * @param {PowerLoggerContext} context additional context object to append more data along with user_id
   */
  public debug(message: string, context: PowerLoggerContext = {}) {
    if (process.env.DEBUG === 'true') {
      const { user_id, ...extra } = context
      const details: CaptureContext = {
        user: user_id !== undefined ? { id: user_id } : undefined,
        extra,
        level: 'debug',
      }

      console.debug(message)

      if (process.env.NODE_ENV === 'production')
        captureMessage(message, details)
    }
  }
  /**
   * @param {string} message console warning message to send to display
   * @param {PowerLoggerContext} context additional context object to append more data along with user_id
   */
  public warn(message: string, context: PowerLoggerContext = {}) {
    const { user_id, ...extra } = context
    const details: CaptureContext = {
      user: user_id !== undefined ? { id: user_id } : undefined,
      extra,
      level: 'warning',
    }

    console.warn(message)

    if (process.env.NODE_ENV === 'production') captureMessage(message, details)
  }
  /**
   * @param {string} message console info message to send to display
   * @param {PowerLoggerContext} context additional context object to append more data along with user_id
   */
  public info(message: string, context: PowerLoggerContext = {}) {
    const { user_id, ...extra } = context
    const details: CaptureContext = {
      user: user_id !== undefined ? { id: user_id } : undefined,
      extra,
      level: 'info',
    }

    console.info(message)

    if (process.env.NODE_ENV === 'production') captureMessage(message, details)
  }
  /**
   * @param {TError} error error to send to sentry
   * @param {PowerLoggerContext} context additional context object to append more data along with user_id
   */
  public error<TError extends Error = Error>(
    error: TError,
    context: PowerLoggerContext = {}
  ) {
    const { user_id, ...extra } = context
    const details: CaptureContext = {
      user: user_id !== undefined ? { id: user_id } : undefined,
      extra,
      level: 'error',
    }

    console.error(error)

    if (process.env.NODE_ENV === 'production') captureException(error, details)
  }
  /**
   * @param {TError} error error to send to sentry
   * @param {PowerLoggerContext} context additional context object to append more data along with user_id
   */
  public fatal<TError extends Error = Error>(
    error: TError,
    context: PowerLoggerContext = {}
  ) {
    const { user_id, ...extra } = context
    const details: CaptureContext = {
      user: user_id !== undefined ? { id: user_id } : undefined,
      extra,
      level: 'fatal',
    }

    console.error(error)

    if (process.env.NODE_ENV === 'production') captureException(error, details)
  }
}

const logger = new Logger()
export default logger
