/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AccountAddDocumentsRequestModel = {
    document?: Blob;
    document_type?: AccountAddDocumentsRequestModel.document_type;
};

export namespace AccountAddDocumentsRequestModel {

    export enum document_type {
        ARTICLES_OF_INCORPORATION = 'ARTICLES_OF_INCORPORATION',
        BANK_STATEMENT = 'BANK_STATEMENT',
        TAX_FILING = 'TAX_FILING',
        OPERATING_DOCS = 'OPERATING_DOCS',
    }


}

