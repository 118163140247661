/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CardReplacementRequest = {
    /**
     * Valid values: stolen, lost, damaged (this status will only mark the card as closed once the new card is activated), reissue, breach, other
     */
    reason?: CardReplacementRequest.reason;
};

export namespace CardReplacementRequest {

    /**
     * Valid values: stolen, lost, damaged (this status will only mark the card as closed once the new card is activated), reissue, breach, other
     */
    export enum reason {
        STOLEN = 'stolen',
        LOST = 'lost',
        DAMAGED = 'damaged',
        REISSUE = 'reissue',
        BREACH = 'breach',
        OTHER = 'other',
    }


}

