import { frontendApiClient } from '~/axios/frontend-api-client'
import type {
  CardResponse,
  CardActivationRequest,
  CardReplacementRequest,
} from '~/services/dto/ts/card'
import { CardStatus } from '~/services/dto/ts/card'

export type Card = CardResponse

export class CardModel implements Card {
  user_id: string
  brand_id: string
  card_id: string
  account_id: string
  masked_card_number: string
  name_on_card: string
  card_type: CardResponse.card_type
  expiration: string
  card_status: CardStatus
  invalid_card_activation_attempts?: number
  cancellation_date?: string
  cvv: string
  created_at: string

  constructor(card: Card) {
    this.user_id = card.user_id
    this.brand_id = card.brand_id
    this.card_id = card.card_id
    this.account_id = card.account_id
    this.masked_card_number = card.masked_card_number
    this.name_on_card = card.name_on_card
    this.card_type = card.card_type
    this.expiration = card.expiration
    this.card_status = card.card_status
    this.invalid_card_activation_attempts =
      card.invalid_card_activation_attempts
    this.cancellation_date = card.cancellation_date
    this.cvv = card.cvv
    this.created_at = card.created_at
  }

  get CardNumberLast4(): string {
    const length = this.masked_card_number.length

    if (length <= 4) return this.masked_card_number

    return this.masked_card_number.substring(length - 4)
  }

  get isNewCardPendingActivation(): boolean {
    return this.card_status.status === CardStatus.status.INACTIVE
  }

  get isReissuedCardPendingActivation(): boolean {
    return this.card_status.status === CardStatus.status.SUSPENDED
  }

  get isCardLocked(): boolean {
    return this.card_status.status === CardStatus.status.LOCKED
  }

  get isCardCancelled(): boolean {
    return this.card_status.status === CardStatus.status.CANCELLED
  }

  get isCardLost(): boolean {
    return this.card_status.reason === CardStatus.reason.LOST
  }

  get isCardStolen(): boolean {
    return this.card_status.reason === CardStatus.reason.STOLEN
  }

  get isCardPendingActivation(): boolean {
    return (
      this.isNewCardPendingActivation || this.isReissuedCardPendingActivation
    )
  }

  get isCardTooStaleToActivate(): boolean {
    return this.card_status.reason === CardStatus.reason.NEVER_ACTIVATED
  }

  get isCardActive(): boolean {
    return this.card_status.status === CardStatus.status.ACTIVE
  }

  get isCardReplaced(): boolean {
    return this.isCardLost || this.isCardStolen || this.isCardCancelled
  }

  get hasSpecialStatus(): boolean {
    return (
      !this.isCardActive ||
      this.isCardLocked ||
      this.isCardReplaced ||
      this.isCardTooStaleToActivate ||
      this.isNewCardPendingActivation ||
      this.isReissuedCardPendingActivation
    )
  }

  get isCardExperienceLocked(): boolean {
    return this.isNewCardPendingActivation || this.isCardTooStaleToActivate
  }
}

export interface CardServiceInterface {
  getCardDetails(card_id: string): Promise<Card>
  getCardDetailsForAllCards(): Promise<Array<Card>>
  lock(card_id: string): Promise<number>
  unlock(card_id: string): Promise<number>
  activate(card_id: string, data: CardActivationRequest): Promise<number>
  replace(card_id: string, data: CardReplacementRequest): Promise<Card>
}

export const CardService: CardServiceInterface = {
  async getCardDetails(card_id: string) {
    const response = await frontendApiClient.get<Card>(
      `/card/v1/cards/${card_id}`
    )
    return response.data
  },

  async getCardDetailsForAllCards() {
    const response = await frontendApiClient.get<Array<Card>>('/card/v1/cards')
    return response.data
  },

  async lock(card_id: string) {
    const response = await frontendApiClient.post(
      `/card/v1/cards/${card_id}/lock`
    )
    return response.status
  },

  async unlock(card_id: string) {
    const response = await frontendApiClient.post(
      `/card/v1/cards/${card_id}/unlock`
    )
    return response.status
  },

  async activate(card_id: string, data: CardActivationRequest) {
    const response = await frontendApiClient.post(
      `/card/v1/cards/${card_id}/activate`,
      data
    )
    return response.status
  },

  async replace(card_id: string, data: CardReplacementRequest) {
    const response = await frontendApiClient.post(
      `/card/v1/cards/${card_id}/replace`,
      data
    )

    return response.data
  },
}
