/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PaymentScheduleRequest = {
    bank_account_id: string;
    payment_amount?: number;
    payment_type: PaymentScheduleRequest.payment_type;
    payment_date?: string;
    payment_frequency: PaymentScheduleRequest.payment_frequency;
};

export namespace PaymentScheduleRequest {

    export enum payment_type {
        MINIMUM_PAYMENT = 'MINIMUM_PAYMENT',
        STATEMENT_BALANCE = 'STATEMENT_BALANCE',
        CURRENT_BALANCE = 'CURRENT_BALANCE',
        FIXED_AMOUNT = 'FIXED_AMOUNT',
        ENUM_UNKNOWN = 'ENUM_UNKNOWN',
    }

    export enum payment_frequency {
        MONTHLY_SPECIFIC_DAY = 'MONTHLY_SPECIFIC_DAY',
        ONE_TIME = 'ONE_TIME',
        ENUM_UNKNOWN = 'ENUM_UNKNOWN',
    }


}

