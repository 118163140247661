/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Address } from './Address';
export type UserRequest = {
    first_name: string;
    middle_name?: string;
    last_name: string;
    date_of_birth: string;
    email: string;
    ssn: string;
    phone_number: string;
    address?: Address;
    citizenship_status?: UserRequest.citizenship_status;
    business_name?: string;
    /**
     * Identifier to link a user to a specific product they are associated with in the brands system
     */
    external_product_id?: string;
};
export namespace UserRequest {
    export enum citizenship_status {
        US_CITIZEN = 'US_CITIZEN',
        US_PERMANENT_RESIDENT = 'US_PERMANENT_RESIDENT',
        NON_US_CITIZEN = 'NON_US_CITIZEN',
    }
}

