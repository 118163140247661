/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TransactionDetails = {
    transaction_id: string;
    transaction_timestamp: string;
    amount: number;
    status: TransactionDetails.status;
    transaction_type: string;
    transaction_mcc?: string;
    merchant_id?: string;
    merchant_name?: string;
    merchant_city?: string;
    merchant_state?: string;
    card_last4_digits?: string;
    reward_amount?: number;
};

export namespace TransactionDetails {

    export enum status {
        PENDING = 'PENDING',
        SETTLED = 'SETTLED',
        DECLINED = 'DECLINED',
        HOLD = 'HOLD',
        ALL = 'ALL',
    }


}

