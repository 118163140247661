import type {
  ApplicationPageCopy,
  FeeRow,
  Incentive,
  Links,
  Offer,
  Reward,
} from '~/constants/template'

export const createTypographyStyle = (
  fontWeight: number,
  fontSize: number,
  lineHeight: string,
  letterSpacing = 0
) => {
  return {
    fontWeight,
    fontSize,
    lineHeight: lineHeight,
    letterSpacing,
  }
}

export const SOFIA_PRO_FONT_FACE = `
@font-face {
    font-family: 'SofiaPro';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('SofiaPro'), url(/fonts/SofiaProRegular.otf) format('opentype');
  }
  
  @font-face {
    font-family: 'SofiaPro';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('SofiaPro'), url(/fonts/SofiaProBold.otf) format('opentype');
  }
`

export const INTER_FONT_FACE = `
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local('Inter'), url(/fonts/Inter-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local('Inter'), url(/fonts/Inter-Bold.ttf) format('truetype');
}
`

export const createDefaultIncentivesCopy = (
  brandName: string
): Array<Incentive> => {
  return [
    {
      title: '3x',
      copy: `On all ${brandName} purchases`,
    },
    {
      title: '2x',
      copy: `On all ${brandName} trips`,
    },
    {
      title: '1x',
      copy: `Anywhere else you use your card`,
    },
  ]
}

export const createDefaultFeeRows = (): Array<FeeRow> => {
  return [
    {
      title: 'Annual Fee',
      explanation: '$0',
    },
    {
      title: 'Foreign Transaction Fee',
      explanation: '$0',
    },
    {
      title: 'Late Payment Fee',
      explanation: 'Up to $40',
    },
    {
      title: 'Returned Payment Fee',
      explanation: 'Up to $40',
    },
  ]
}

export const createDefaultApplicationCopy = (
  brandName: string
): ApplicationPageCopy => {
  return {
    title: ['Get free trips with a premium credit card'],
    incentives: createDefaultIncentivesCopy(brandName),
    aprLow: '18.49%',
    aprHigh: '32.49%',
    feesRows: createDefaultFeeRows(),
  }
}

export const createDefaultOffers = (brandName: string): Array<Offer> => {
  return [
    {
      title: '3% back at herman miller',
      description: `Get 3% back when you use your ${brandName} Card at Herman Miller.`,
      image: `/brand/images/merchant-x/offer-herman-miller.webp`,
      linkHref: '',
      linkText: 'Shop Now',
    },
    {
      title: '3% back at herman miller',
      description: `Get 3% back when you use your ${brandName} Card at Herman Miller.`,
      image: `/brand/images/merchant-x/offer-herman-miller.webp`,
      linkHref: '',
      linkText: 'Shop Now',
    },
    {
      title: '2% back at herman miller',
      description: `Get 2% back when you use your ${brandName} Card at Herman Miller.`,
      image: `/brand/images/merchant-x/offer-herman-miller.webp`,
      linkHref: '',
      linkText: 'Shop Now',
    },
  ]
}

export const createDefaultRewards = (): Array<Reward> => {
  return [
    { title: '3x Points', copy: 'Copy for title 1' },
    { title: '2x Points', copy: 'Copy for title 2' },
    { title: '1x Point', copy: 'Copy for title 3' },
  ]
}

export const createDefaultLinks = (): Links => {
  return {
    faq: 'https://www.marqeta.com/',
    homepage: 'https://www.marqeta.com/',
    legal: {
      loyaltyProgram: '',
      autopayAgreement: '',
      cardholderAgreement: '',
      ecommDisclosure: '',
      autodialerAuth: '',
      creditReportAuth: '',
    },
  }
}
