/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CardProduct = {
    card_product_token: string;
    card_type: CardProduct.card_type;
};

export namespace CardProduct {

    export enum card_type {
        VIRTUAL = 'VIRTUAL',
        PHYSICAL = 'PHYSICAL',
    }


}

