import type { Template } from './template'
import {
  Affinipay,
  Airbase,
  Coupa,
  Comcred,
  ITS,
  Koffie,
  MerchantB,
  MerchantM,
  MerchantT,
  MerchantX,
} from './templates'

export const PRIME_RATE = 8.5

export enum BRAND_ID {
  Affinipay = '1b396b7d-16f8-496f-8890-5f95afc2e800',
  Affinipay2 = 'fdf2e5a4-c59b-4a1c-8113-cadf9ff7a21e',
  Airbase = '517d61cd-0d31-48a1-b175-851b892e3e14',
  Coupa = '9c79ea0d-d9b5-4b9e-bb14-a9b89b2a4b96',
  Comcred = 'b0187038-5fe0-44e5-a2e3-87c71f1f25fe',
  ITS = '49cf8ce9-2760-4b02-91e5-573cd193c906',
  Koffie = 'aea51522-e4fe-4e7f-bd60-1e533b17f5e8',
  MerchantB = '53f0c13d-5d48-484b-9429-52260b6b8170',
  MerchantM = 'a4f23d4c-f0b5-4dfb-bd02-63e758ebcc5f',
  MerchantX = '923b38a6-d757-407e-9f76-6ec2b88e38d6',
  MerchantT = '5415d428-dba0-4bc4-a8d8-0237e31affd3',
  Power = 'cf263577-6930-4664-801c-5b58bac7419e',
}

export const BRANDS: Record<string, Template> = {
  [BRAND_ID.Affinipay]: Affinipay,
  [BRAND_ID.Affinipay2]: Affinipay,
  [BRAND_ID.Airbase]: Airbase,
  [BRAND_ID.Coupa]: Coupa,
  [BRAND_ID.Comcred]: Comcred,
  [BRAND_ID.ITS]: ITS,
  [BRAND_ID.Koffie]: Koffie,
  [BRAND_ID.MerchantB]: MerchantB,
  [BRAND_ID.MerchantM]: MerchantM,
  [BRAND_ID.MerchantX]: MerchantX,
  [BRAND_ID.MerchantT]: MerchantT,
  [BRAND_ID.Power]: MerchantX,
}
