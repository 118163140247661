import type { FunctionComponent, ReactNode } from 'react'
import type { CardStoreState } from './card'
import { CardStoreProvider, DEFAULT_CARD_STATE } from './card'

interface Props {
  children: ReactNode
  initialState?: CardStoreState
}

export const StoreProvider: FunctionComponent<Props> = ({
  children,
  initialState = DEFAULT_CARD_STATE,
}) => {
  return (
    <CardStoreProvider initialState={initialState}>
      {children}
    </CardStoreProvider>
  )
}
